<template>
    <Editor ref="editorRef" v-bind="$attrs">
        <template #toolbar>
            <span class="ql-formats">
                <select class="ql-header">
                    <option value="1">Título</option>
                    <option value="2">Subtítulo</option>
                    <option value="3">Destacado</option>
                    <option value="0" selected>Normal</option>
                </select>
                <select class="ql-font">
                    <option value="sans-serif" selected>Sans Serif</option>
                    <option value="serif">Serif</option>
                    <option value="monospace">Monoespacio</option>
                </select>
            </span>
            <span class="ql-formats">
                <button class="ql-bold" type="button"></button>
                <button class="ql-italic" type="button"></button>
                <button class="ql-underline" type="button"></button>
                <button class="ql-strike" type="button"></button>
            </span>
            <span class="ql-formats">
                <select class="ql-color"><option selected="selected"></option><option value="#e60000"></option><option value="#ff9900"></option><option value="#ffff00"></option><option value="#008a00"></option><option value="#0066cc"></option><option value="#9933ff"></option><option value="#ffffff"></option><option value="#facccc"></option><option value="#ffebcc"></option><option value="#ffffcc"></option><option value="#cce8cc"></option><option value="#cce0f5"></option><option value="#ebd6ff"></option><option value="#bbbbbb"></option><option value="#f06666"></option><option value="#ffc266"></option><option value="#ffff66"></option><option value="#66b966"></option><option value="#66a3e0"></option><option value="#c285ff"></option><option value="#888888"></option><option value="#a10000"></option><option value="#b26b00"></option><option value="#b2b200"></option><option value="#006100"></option><option value="#0047b2"></option><option value="#6b24b2"></option><option value="#444444"></option><option value="#5c0000"></option><option value="#663d00"></option><option value="#666600"></option><option value="#003700"></option><option value="#002966"></option><option value="#3d1466"></option></select>
                <select class="ql-background"><option value="#000000"></option><option value="#e60000"></option><option value="#ff9900"></option><option value="#ffff00"></option><option value="#008a00"></option><option value="#0066cc"></option><option value="#9933ff"></option><option selected="selected"></option><option value="#facccc"></option><option value="#ffebcc"></option><option value="#ffffcc"></option><option value="#cce8cc"></option><option value="#cce0f5"></option><option value="#ebd6ff"></option><option value="#bbbbbb"></option><option value="#f06666"></option><option value="#ffc266"></option><option value="#ffff66"></option><option value="#66b966"></option><option value="#66a3e0"></option><option value="#c285ff"></option><option value="#888888"></option><option value="#a10000"></option><option value="#b26b00"></option><option value="#b2b200"></option><option value="#006100"></option><option value="#0047b2"></option><option value="#6b24b2"></option><option value="#444444"></option><option value="#5c0000"></option><option value="#663d00"></option><option value="#666600"></option><option value="#003700"></option><option value="#002966"></option><option value="#3d1466"></option></select>
            </span>
            <span class="ql-formats">
                <button class="ql-list" type="button" value="ordered"></button>
                <button class="ql-list" type="button" value="bullet"></button>
                <select class="ql-align"><option selected></option><option value="center"></option><option value="right"></option><option value="justify"></option></select>
            </span>
            <span class="ql-formats">
                <button class="ql-link" type="button"></button>
                <button class="ql-image" type="button"></button>
            </span>
            <span class="ql-formats">
                <button class="ql-clean" type="button"></button>
            </span>
        </template>
    </Editor>
    <Dialog v-model:visible="estateImagesDialog" :style="{width: '450px'}" header="Imágenes" :modal="true" class="p-fluid">
        <div class="confirmation-content">
            <FileUpload mode="basic" :multiple="false" accept="image/*" :auto="true" :customUpload="true" @uploader="imgUpload" chooseLabel="Subir imagen" class="mb-3" :disabled="working" />
            <div class="grid">
                <div class="col-12 md:col-4 flex flex-column" v-for="(img, i) in estateImages" :key="i">
                    <div class="flex-grow-1 w-full flex flex-column justify-content-end mb-1"><img :src="imageUrl(img)" alt="" class="w-full"></div>
                    <span class="p-buttonset">
                        <Button icon="pi pi-check" class="p-button-sm" @click="insertComunidaeImage(img)" />
                        <Button icon="pi pi-trash" class="p-button-sm p-button-danger" @click="openDeleteImageDialog(img)" />
                    </span>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="estateImagesDialog = false" :disabled="working" />
        </template>
    </Dialog>
    <Dialog v-model:visible="deleteImageDialog" :style="{width: '450px'}" header="Confirmar eliminación" :modal="true" class="p-fluid">
        <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span>¿Quieres eliminar la imagen?</span>
        </div>
        <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteImageDialog = false" :disabled="working" />
            <Button label="Sí" icon="pi pi-trash" class="p-button-text p-button-danger" @click="deleteImage" :disabled="working" />
        </template>
    </Dialog>
</template>

<script>
import { ref } from 'vue';
import ApiService from '@/service/ApiService.js';
import EstateService from '@/service/EstateService.js';
import ErrorReportService from '@/service/ErrorReportService.js';

export default {
    inheritAttrs: false,
    setup() {
        const editorRef = ref();

        return {editorRef};
    },
    data() {
        return {
            working: false,
            estateImages: [],
            estateImagesDialog: false,
            deleteImageDialog: false,
            imageToDelete: null
        };
    },
    estateService: null,
    errorReportService: null,
    created() {
        this.estateService = new EstateService();
        this.errorReportService = new ErrorReportService();
    },
    mounted() {
        setTimeout(() => {
            let toolbar = this.editorRef.quill.getModule('toolbar');
            toolbar.addHandler('image', this.quillComunidaeImg);
        }, 500);
    },
    methods: {
        quillComunidaeImg() {
            if (!this.working) {
                if (this.estateImages.length === 0) {
                    this.working = true;

                    this.estateService.fetchAllImages(this.$appState.estate.id)
                        .then(response => {
                            this.estateImages = response.data.files;
                            this.estateImagesDialog = true;
                            this.working = false;
                        })
                        .catch(error => {
                            console.error(error);
                            this.errorReportService.sendReport(
                                this.$appState.visitorId,
                                window.navigator.userAgent,
                                this.$appState.estate.id,
                                this.errorReportService.getRequestData(error.request),
                                this.errorReportService.getErrorData(error.response),
                            );
                            this.working = false;
                        });
                } else {
                    this.estateImagesDialog = true;
                }
            }
        },
        insertComunidaeImage(img) {
            const range = this.editorRef.quill.getSelection();
            const index = range?.index ?? 0;
            this.editorRef.quill.insertEmbed(index, 'image', ApiService.getImageUrl(this.$appState.estate.id, img), 'user');
            this.estateImagesDialog = false;
        },
        imageUrl(img) {
            return ApiService.getImageUrl(this.$appState.estate.id, img);
        },
        openDeleteImageDialog(img) {
            this.imageToDelete = img;
            this.deleteImageDialog = true;
        },
        deleteImage() {
            if (!this.working) {
                this.working = true;

                this.estateService.deleteImage(this.$appState.estate.id, this.imageToDelete)
                    .then(response => {
                        this.imageToDelete = null;
                        this.deleteImageDialog = false;

                        if (response.data.deleted) {
                            this.estateService.fetchAllImages(this.$appState.estate.id)
                                .then(response => {
                                    this.estateImages = response.data.files;
                                    this.working = false;
                                })
                                .catch(error => {
                                    console.error(error);
                                    this.working = false;
                                });
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        this.errorReportService.sendReport(
                            this.$appState.visitorId,
                            window.navigator.userAgent,
                            this.$appState.estate.id,
                            this.errorReportService.getRequestData(error.request),
                            this.errorReportService.getErrorData(error.response),
                        );
                    });
            }
        },
        imgUpload(ev) {
            if (!this.working) {
                this.working = true;
                let fd = new FormData();
                fd.append('img', ev.files[0]);

                this.estateService.uploadImage(this.$appState.estate.id, fd)
                    .then(response => {
                        this.estateImages.push(response.data.img);
                        this.working = false;
                    })
                    .catch(error => {
                        console.error(error);
                        this.errorReportService.sendReport(
                            this.$appState.visitorId,
                            window.navigator.userAgent,
                            this.$appState.estate.id,
                            this.errorReportService.getRequestData(error.request, {
                                image_file: ev.files[0].name,
                                image_size: ev.files[0].size
                            }),
                            this.errorReportService.getErrorData(error.response),
                        );
                        this.working = false;
                    });
            }
        }
    }
};
</script>