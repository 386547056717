<template>
	<div class="page-content no-padding">
        <div class="grid">
            <div class="col-12">
                <Toolbar class="p-mb-4" v-if="userCanCreate">
                    <template #start>
                        <Button label="Crear" icon="pi pi-plus" class="p-button-success mr-2" @click="showCreatePageDialog" />
                    </template>
                    <template #end>
                    </template>
                </Toolbar>
                <DataTable :value="pages" class="p-datatable-gridlines" dataKey="id" :rowHover="true" :loading="loading" responsiveLayout="scroll" :rowClass="rowClass" sortField="created_at" :sortOrder="-1" removableSort>
                    <template #header>
                        <div class="table-header flex flex-column md:flex-row md:justify-content-between">
                            <h5 class="mb-2 md:m-0 md:align-self-center">Páginas</h5>
                            <div class="flex flex-column md:flex-row">
                                <ToggleButton v-model="withTrashed" onLabel="Sin eliminadas" offLabel="Ver eliminadas" onIcon="pi pi-eye-slash" offIcon="pi pi-eye" @change="fetchPages" class="mb-2 mr-0 md:mb-0 md:mr-2" v-if="userCanCreate && userCanRestore" />
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search" />
                                    <InputText v-model="searchQuery" placeholder="Buscar..." />
                                </span>
                            </div>
                        </div>
                    </template>
                    <template #empty>
                        No hay páginas.
                    </template>
                    <template #loading>
                        Cargando datos, espera por favor...
                    </template>
                    <Column header="Nombre" field="name" style="min-width:12rem" :sortable="true">
                        <template #body="{data}">
                            <div class="flex flex-row">
                                <iconify-icon :icon="data.content.icon ?? 'mdi:text-box'" class="mr-2" style="font-size: 1.6em;"></iconify-icon>
                                {{data.name}}
                            </div>
                        </template>
                    </Column>
                    <Column header="Creada el" field="created_at" dataType="created_at" style="min-width:10rem" :sortable="true">
                        <template #body="{data}">
                            {{formatDate(data.created_at)}}
                        </template>
                    </Column>
                    <Column :exportable="false" style="min-width:8rem">
                        <template #body="{data}">
                            <template v-if="!data.deleted_at">
                                <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="showUpdatePageDialog(data.id)" v-if="userCanUpdate" />
                                <Button v-if="userCanDelete && !canDeletePage(data)" icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDeletePage(data)" />
                                <Button v-if="userCanDelete && canDeletePage(data)" icon="pi pi-trash" class="p-button-rounded p-button-danger" v-tooltip.left="{label: 'No se puede eliminar'}" disabled />
                            </template>
                            <template v-else-if="userCanRestore">
                                <Button icon="pi pi-undo" class="p-button-rounded p-button-success" @click="confirmRestorePage(data)" />
                            </template>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
    <Dialog v-model:visible="deletePageDialog" :style="{width: '450px'}" header="Confirmar eliminación" :modal="true">
        <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="page">¿Quieres eliminar la página <b>{{page.name}}</b>?</span>
        </div>
        <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="deletePageDialog = false"/>
            <Button label="Sí" icon="pi pi-trash" class="p-button-text p-button-danger" @click="deletePage" />
        </template>
    </Dialog>

    <Dialog v-model:visible="restorePageDialog" :style="{width: '450px'}" header="Confirmar restauración" :modal="true">
        <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="page">¿Quieres restaurar la página <b>{{page.name}}</b>?</span>
        </div>
        <template #footer>
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="restorePageDialog = false"/>
            <Button label="Sí" icon="pi pi-undo" class="p-button-text p-button-success" @click="restorePage" />
        </template>
    </Dialog>

    <Dialog v-model:visible="createPageDialog" :style="dialogWidth" header="Nueva página" :modal="true" class="p-fluid">
        <div class="confirmation-content">
            <div class="field mb-4">
                <label for="name">Nombre</label>
                <InputText v-model="v$.newPage.name.$model" id="name" type="text" class="w-full mb-2" :class="{'p-invalid': v$.newPage.name.$error}" required @keyup="newPageNameChange" />
                <div v-for="error of v$.newPage.name.$errors" :key="error.$uid">
                    <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                </div>
            </div>
            <div class="field mb-4">
                <label for="slug">Meta URL (lo que figura en la URL al ver la página)</label>
                <InputText v-model.trim="v$.newPage.slug.$model" id="slug" type="text" class="w-full mb-2" :class="{'p-invalid': v$.newPage.slug.$error}" required />
                <div v-for="error of v$.newPage.slug.$errors" :key="error.$uid">
                    <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                </div>
            </div>
            <div class="field mb-4">
                <div class="flex flex-row align-items-center">
                    <iconify-icon :icon="v$.newPage.icon.$model" class="mr-4" style="font-size: 1.8em;"></iconify-icon>
                    <Button label="Cambiar icono" icon="pi pi-search" class="p-button-primary w-auto" @click="selectIcon(false)" :disabled="working" />
                    <ColorPicker v-model="newPage.iconColor" class="ml-3" style="width: 42px;" />
                </div>
                <div v-for="error of v$.newPage.icon.$errors" :key="error.$uid">
                    <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                </div>
            </div>
            <div class="field mb-4">
                <label for="role">Tipo de página</label>
                <Dropdown v-model="v$.newPage.pageType.$model" :options="pageTypes" optionLabel="name" class="w-full mb-2" :class="{'p-invalid': v$.newPage.pageType.$error}" required />
                <div v-for="error of v$.newPage.pageType.$errors" :key="error.$uid">
                    <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                </div>
            </div>
            <div class="field mb-4" v-show="v$.newPage.pageType.$model.value === 'basic'">
                <CustomEditor v-model="v$.newPage.content.$model" id="content" class="w-full mb-2" :class="{'p-invalid': v$.newPage.content.$error}" editorStyle="height: 300px;" required />
                <div v-for="error of v$.newPage.content.$errors" :key="error.$uid">
                    <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                </div>
            </div>

            <div class="mt-4" v-if="registerError"><small class="p-error font-bold">{{ registerError }}</small></div>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="createPageDialog = false" :disabled="working" />
            <Button label="Crear página" icon="pi pi-page-plus" :loading="working" class="p-button-text p-button-success" @click="createPage" :disabled="canCreatePage || working" />
        </template>
    </Dialog>

    <Dialog v-model:visible="updatePageDialog" :style="dialogWidth" header="Editar página" :modal="true" class="p-fluid">
        <div class="confirmation-content">
            <div class="field mb-4">
                <label for="title">Nombre</label>
                <InputText v-model="v$.updatePage.name.$model" id="name" type="text" class="w-full mb-2" :class="{'p-invalid': v$.updatePage.name.$error}" required @keyup="updatePageNameChange" />
                <div v-for="error of v$.updatePage.name.$errors" :key="error.$uid">
                    <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                </div>
            </div>
            <div class="field mb-4">
                <label for="slug">Meta URL (lo que figura en la URL al ver la página)</label>
                <InputText v-model.trim="v$.updatePage.slug.$model" id="slug" type="slug" class="w-full mb-2" :class="{'p-invalid': v$.updatePage.slug.$error}" required />
                <div v-for="error of v$.updatePage.slug.$errors" :key="error.$uid">
                    <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                </div>
            </div>
            <div class="field mb-4">
                <div class="flex flex-row align-items-center">
                    <iconify-icon :icon="v$.updatePage.icon.$model" class="mr-4" style="font-size: 1.8em;"></iconify-icon>
                    <Button label="Cambiar icono" icon="pi pi-search" class="p-button-primary w-auto" @click="selectIcon(true)" :disabled="working" />
                    <ColorPicker v-model="updatePage.iconColor" class="ml-3" style="width: 42px;" />
                </div>
                <div v-for="error of v$.updatePage.icon.$errors" :key="error.$uid">
                    <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                </div>
            </div>
            <div class="field mb-4" v-show="page.content.page_type === 'basic'">
                <CustomEditor v-model="v$.updatePage.content.$model" id="content" class="w-full mb-2" :class="{'p-invalid': v$.updatePage.content.$error}" editorStyle="height: 300px;" required />
                <div v-for="error of v$.updatePage.content.$errors" :key="error.$uid">
                    <small class="p-error">{{ validationMessages[error.$validator] }}</small>
                </div>
            </div>

            <div class="mt-4" v-if="updateError"><small class="p-error font-bold">{{ updateError }}</small></div>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="updatePageDialog = false" :disabled="working" />
            <Button label="Guardar" icon="pi pi-save" :loading="working" class="p-button-text p-button-success" @click="savePage" :disabled="canUpdatePage || working" />
        </template>
    </Dialog>
</template>

<script>
import { ref } from 'vue';
import RouteGuardService from '@/service/RouteGuardService.js';
import AuthService from '@/service/AuthService.js';
import PageService from '@/service/PageService.js';
import ErrorReportService from '@/service/ErrorReportService.js';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import CustomValidators from '@/customValidators';
import ValidationMessages from '@/validationMessages';
import CustomEditor from './CustomEditor.vue';

const compUrl = '/pages';

const PageTypes = [
    { name: 'Básica', value: 'basic' },
    { name: 'Personalizada', value: 'custom' },
];

export default {
    beforeRouteEnter() {
        let goTo = RouteGuardService.isLoggedIn();

        if (goTo !== true) {
            window.sessionStorage.setItem('afterLogin', compUrl);
        }

        return goTo;
    },
    setup() {
        const customEditorRef = ref(null);

        return { v$: useVuelidate(), customEditorRef };
    },
    data() {
        return {
            pages: null,
            withTrashed: false,
            loading: true,
            deletePageDialog: false,
            restorePageDialog: false,
            createPageDialog: false,
            updatePageDialog: false,
            page: null,
            query: '',
            qTimeout: null,
            newPage: {
                name: '',
                slug: '',
                icon: 'mdi:text-box',
                iconColor: '6366F1',
                pageType: PageTypes[0],
                content: '',
            },
            validationMessages: {},
            working: false,
            registerError: null,
            updatePage: {
                name: '',
                slug: '',
                icon: 'mdi:text-box',
                iconColor: '6366F1',
                content: '',
            },
            updateError: null,
            pageTypes: PageTypes
        }
    },
    authService: null,
    pageService: null,
    errorReportService: null,
    created() {
        this.authService = new AuthService();
        this.pageService = new PageService();
        this.errorReportService = new ErrorReportService();
        this.validationMessages = ValidationMessages;
    },
    validations() {
        return {
            newPage: {
                name: { required },
                slug: { required, slug: CustomValidators.slug },
                icon: { required },
                pageType: { required, validPageType: CustomValidators.validPageType },
                content: { required },
            },
            updatePage: {
                name: { required },
                slug: { required, slug: CustomValidators.slug },
                icon: { required },
                content: { required },
            }
        };
    },
    emits: ['updateBreadcrumbs', 'selectIcon'],
    mounted() {
        this.$emit('updateBreadcrumbs', {label: 'Páginas', to: compUrl, replace: true});

        if (!this.$appState.estate) {
            this.$watch(
                () => this.$appState.estate,
                () => {
                    this.fetchPages();
                },
            );
        } else {
            this.fetchPages();
        }
    },
    computed: {
        searchQuery: {
            get() {
                return this.query;
            },
            set(value) {
                if (this.qTimeout) {
                    clearTimeout(this.qTimeout);
                }
                this.qTimeout = setTimeout(() => {
                    this.query = value;

                    if (this.query.length >= 3 || this.query.length === 0) {
                        this.fetchPages();
                    }
                }, 500);
            }
        },
        userCanCreate() {
            return this.$appState.tokenAbilities.includes('all') || this.$appState.tokenAbilities.includes('page:create');
        },
        userCanUpdate() {
            return this.$appState.tokenAbilities.includes('all') || this.$appState.tokenAbilities.includes('page:update');
        },
        userCanDelete() {
            return this.$appState.tokenAbilities.includes('all') || this.$appState.tokenAbilities.includes('page:delete');
        },
        userCanRestore() {
            return this.$appState.tokenAbilities.includes('all') || this.$appState.tokenAbilities.includes('page:restore');
        },
        canCreatePage() {
            return this.v$.newPage.name.$errors.length !== 0
                || this.v$.newPage.slug.$errors.length !== 0
                || this.v$.newPage.icon.$errors.length !== 0
                || this.v$.newPage.pageType.$errors.length !== 0
                || this.v$.newPage.content.$errors.length !== 0
                || this.newPage.name.length === 0
                || this.newPage.slug.length === 0
                || this.newPage.icon.length === 0
                || this.newPage.pageType.length === 0
                || this.newPage.content.length === 0;
        },
        canUpdatePage() {
            return this.v$.updatePage.name.$errors.length !== 0
                || this.v$.updatePage.slug.$errors.length !== 0
                || this.v$.updatePage.icon.$errors.length !== 0
                || this.v$.updatePage.content.$errors.length !== 0
                || this.updatePage.name.length === 0
                || this.updatePage.slug.length === 0
                || this.updatePage.icon.length === 0
                || this.updatePage.content.length === 0;
        },
        dialogWidth() {
            if (window.innerWidth < 1280) {
                return {width: '90vw'};
            }

            return {width: '75vw'};
        }
    },
    methods: {
        formatDate(value) {
            return value.toLocaleDateString('es-ES', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });
        },
        rowClass(data) {
            return data.deleted_at !== null ? 'deleted-row' : null;
        },
        createSlug(value) {
            let slug = value.toLowerCase().replaceAll(/[^a-z0-9\s-_áéíóúñ]/g, '');
            slug = slug.replaceAll(/á/g, 'a');
            slug = slug.replaceAll(/é/g, 'e');
            slug = slug.replaceAll(/í/g, 'i');
            slug = slug.replaceAll(/ó/g, 'o');
            slug = slug.replaceAll(/ú/g, 'u');
            slug = slug.replaceAll(/ñ/g, 'n');
            slug = slug.replaceAll(/[\s_]/g, '-');

            return slug;
        },
        canDeletePage(page) {
            if (page.hasDocuments || page.content.can_delete) {
                return false;
            }

            return true;
        },
        newPageNameChange(ev) {
            this.newPage.slug = this.createSlug(ev.srcElement.value);
        },
        updatePageNameChange(ev) {
            this.updatePage.slug = this.createSlug(ev.srcElement.value);
        },
        confirmDeleteSelected() {
            this.deletePagesDialog = true;
        },
        confirmDeletePage(page) {
            this.page = page;
            this.deletePageDialog = true;
        },
        confirmRestorePage(page) {
            this.page = page;
            this.restorePageDialog = true;
        },
        fetchPages() {
            if (this.$appState.tokenAbilities.includes('all') || this.$appState.tokenAbilities.includes('page:list')) {
                this.loading = true;
                this.pageService.fetchPages(this.$appState.estate.id, this.withTrashed, this.query).then(response => {
                    this.pages = response.data.pages;
                    this.loading = false;
                    this.pages.forEach(page => page.created_at = new Date(page.created_at));
                }).catch(error => {
                    console.error(error);
                    this.errorReportService.sendReport(
                        this.$appState.visitorId,
                        window.navigator.userAgent,
                        this.$appState.estate.id,
                        this.errorReportService.getRequestData(error.request),
                        this.errorReportService.getErrorData(error.response),
                    );
                    this.loading = false;
                    this.$toast.add({severity:'error', summary: 'Error', detail: 'P-01: No se ha podido obtener las páginas', life: 3000});
                });
            } else {
                this.loading = false;
                this.$toast.add({severity:'error', summary: 'Error', detail: 'P-00: Acceso no autorizado', life: 3000});
            }
        },
        pageChanged(event) {
            this.page = event.page;
            this.fetchPages();
        },
        deletePage() {
            this.deletePageDialog = false;
            this.loading = true;
            this.pageService.deletePage(this.page.id).then(() => {
                this.page = null;
                this.fetchPages();
            }).catch(error => {
                this.errorReportService.sendReport(
                    this.$appState.visitorId,
                    window.navigator.userAgent,
                    this.$appState.estate.id,
                    this.errorReportService.getRequestData(error.request),
                    this.errorReportService.getErrorData(error.response),
                );
                this.loading = false;
                this.$toast.add({severity:'error', summary: 'Error', detail: 'No se ha podido eliminar la página', life: 3000});
            });
        },
        restorePage() {
            this.restorePageDialog = false;
            this.loading = true;
            this.pageService.restorePage(this.page.id).then(() => {
                this.page = null;
                this.fetchPages();
            }).catch(error => {
                this.errorReportService.sendReport(
                    this.$appState.visitorId,
                    window.navigator.userAgent,
                    this.$appState.estate.id,
                    this.errorReportService.getRequestData(error.request),
                    this.errorReportService.getErrorData(error.response),
                );
                this.loading = false;
                this.$toast.add({severity:'error', summary: 'Error', detail: 'No se ha podido restaurar la página', life: 3000});
            });
        },
        showCreatePageDialog() {
            this.newPage = {
                name: '',
                slug: '',
                icon: '',
                iconColor: '6366F1',
                pageType: PageTypes[0],
                content: '',
            };
            this.createPageDialog = true;
        },
        createPage() {
            if (!this.working) {
                this.working = true;
                this.registerError = null;
                let content = {
                    'icon_type': 'icon',
                    'icon': this.newPage.icon,
                    'icon_color': this.newPage.iconColor,
                    'page_type': this.newPage.pageType.value,
                    'layout': []
                };

                if (this.newPage.pageType.value === 'basic') {
                    content.layout = [
                        {
                            'type': 'basic-content',
                            'content': this.newPage.content.replaceAll('target="_blank"', '')
                        }
                    ];
                }

                this.pageService.createPage(this.$appState.estate.id, this.newPage.name, this.newPage.slug, content).then(response => {
                    if (response.data.page) {
                        this.working = false;
                        this.createPageDialog = false;
                        this.selectedFile = null;
                        this.fetchPages();
                    } else {
                        this.registerError = 'ERROR P-03: Ha ocurrido un error al crear la página';
                    }
                }).catch(error => {
                    console.log(error);
                    this.errorReportService.sendReport(
                        this.$appState.visitorId,
                        window.navigator.userAgent,
                        this.$appState.estate.id,
                        this.errorReportService.getRequestData(error.request, {
                            name: this.newPage.name,
                            slug: this.newPage.slug,
                            content: content
                        }),
                        this.errorReportService.getErrorData(error.response),
                    );
                    this.working = false;
                    this.registerError = 'ERROR P-02: Ha ocurrido un error al crear la página';
                });
            }
        },
        showUpdatePageDialog(pageId) {
            this.loading = true;

            this.pageService.fetchPage(pageId).then(response => {
                this.page = response.data.page;
                this.updatePage.name = this.page.name;
                this.updatePage.slug = this.page.slug;
                this.updatePage.icon = this.page.content.icon;
                this.updatePage.iconColor = this.page.content.icon_color ?? '6366F1';
                this.updatePage.content = this.page.content.layout[0].content;
                this.loading = false;
                this.updatePageDialog = true;
            }).catch(error => {
                console.error(error);
                this.errorReportService.sendReport(
                    this.$appState.visitorId,
                    window.navigator.userAgent,
                    this.$appState.estate.id,
                    this.errorReportService.getRequestData(error.request),
                    this.errorReportService.getErrorData(error.response),
                );
                this.loading = false;
                this.$toast.add({severity:'error', summary: 'Error', detail: 'P-04: No se ha podido obtener los datos de la página', life: 3000});
            });
        },
        savePage() {
            if (!this.working) {
                this.working = true;
                this.updateError = null;
                let content = {
                    'icon_type': 'icon',
                    'icon': this.updatePage.icon,
                    'icon_color': this.updatePage.iconColor,
                    'page_type': this.page.content.page_type,
                    'layout': []
                };

                if (this.page.content.page_type === 'basic') {
                    content.layout = [
                        {
                            'type': 'basic-content',
                            'content': this.updatePage.content.replaceAll('target="_blank"', '')
                        }
                    ];
                }

                this.pageService.updatePage(
                    this.page.id,
                    this.updatePage.name,
                    this.updatePage.slug,
                    content,
                ).then(response => {
                    if (response.data.page) {
                        this.working = false;
                        this.updatePageDialog = false;
                        this.page = null;
                        this.fetchPages();
                    } else {
                        this.updateError = 'ERROR P-07: Ha ocurrido un error al actualizar la página';
                    }
                }).catch(error => {
                    console.log(error);
                    this.errorReportService.sendReport(
                        this.$appState.visitorId,
                        window.navigator.userAgent,
                        this.$appState.estate.id,
                        this.errorReportService.getRequestData(error.request, {
                            name: this.updatePage.name,
                            slug: this.updatePage.slug,
                            content: content
                        }),
                        this.errorReportService.getErrorData(error.response),
                    );
                    this.working = false;
                    this.updateError = 'ERROR P-06: Ha ocurrido un error al actualizar la página';
                });
            }
        },
        selectIcon(isUpdate) {
            this.$emit('selectIcon', (icon) => {
                if (!isUpdate) {
                    this.newPage.icon = icon;
                } else {
                    this.updatePage.icon = icon;
                }
            });
        }
    },
    components: {
        'CustomEditor': CustomEditor
    }
}
</script>

<style scoped lang="scss">
    .table-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: 960px) {
            align-items: start;
        }
    }

    ::v-deep(.p-datatable .p-datatable-tbody > tr.deleted-row) {
        background-color: var(--red-50);
    }

	.customer-badge {
		border-radius: 2px;
		padding: .25em .5rem;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 12px;
		letter-spacing: .3px;

		&.status-true {
			background: #C8E6C9;
			color: #256029;
		}

		&.status-false {
			background: #FEEDAF;
			color: #8A5340;
		}
	}

    @media screen and (max-width: 960px) {
        ::v-deep(.p-toolbar) {
            flex-wrap: wrap;
            
            .p-button {
                margin-bottom: 0.25rem;
            }
        }
    }
</style>
